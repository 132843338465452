define("discourse/plugins/discourse-user-card-badges/discourse/initializers/initialize-user-card-badges", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function modifyUserCardContents(api) {
    api.modifyClass("component:user-card-contents", Superclass => class extends Superclass {
      get classNames() {
        const result = [...super.classNames];
        const image = this.get("user.card_badge.image");
        if (image && !image.startsWith("fa-")) {
          result.push("has-card-badge-image");
        }
        return result;
      }
    });
  }
  var _default = _exports.default = {
    name: "user-card-badges",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.19", api => {
        modifyUserCardContents(api);
      });
    }
  };
});